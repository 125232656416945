import React from 'react'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { skills } from "../data";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingLeft:'5%',
            paddingRight: '5%',
            minWidth: '100vw',
            minHeight: '100vh'
        },
        title: {
            padding:'10vh 0'
        },
        item: {
            paddingLeft:130,
            padding: 20,
        },
        paper: {
            textAlign: 'center',
            borderColor: '#FC9885',
            maxWidth: 200,
            transition: 'transform 0.5s',
            '&:hover, &:focus': {
                transform: 'scale3d(1.1,1.1,2)',
                cursor: 'pointer',
                background: '#FC9885',

                '&::after': {
                    opacity: 1
                }
                
            }
        },
        typography: {
            '&:hover': {
                color:'white'
            }
        }
    }
})

export default function Skills() {
    const classes = useStyles();

    return (
        <section id="skills">
            <Container className={classes.container}>
                <Typography variant="h2" color="secondary" className={classes.title}>
                Skills
                </Typography>
                <Grid container>
                    {skills.map((skill) => (
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Paper variant='outlined' elevation='2' className={classes.paper}>
                                <Typography variant="h6" color="textSecondary" className={classes.typography}>
                                    {skill}
                                </Typography>
                            </Paper>   
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section>
    )
}
