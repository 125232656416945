export const projects = [
    {
      id: 1,
      title: "Online Book Store - Web & Mobile Application",
      subtitle: " Web & Mobile Development, REST APIs and Web Services",
      description:
        " ReactJS, HTML, CSS, JavaScript,Spring Boot,Java, Retrofit, MySQL",
      image: "./project-1.gif",
      link: "https://6102f5512ff7b457fa981502--nervous-brattain-99825e.netlify.app/",
    },
    {
      id: 2,
      title: "Online Vehicle Rental Service - Web Application",
      subtitle: " Web Development, System Integrations,and REST APIs",
      description:
        " ReactJS, HTML, CSS, JavaScript,Spring Boot, Java, MySQL",
      image: "./project-2.gif",
      link: "https://6102c795fe9051300dcfbe05--modest-jackson-ef4a10.netlify.app/",
    },
    {
      id: 3,
      title: "Web3 Minting Web Application",
      subtitle: " Web3 Development, Writing and Deploying Smart Contracts to a Test Network",
      description:
        " React, HTML, CSS, JavaScript, Solidity, Hardhat, Ethers, and Chakra UI",
      image: "./project-3.gif",
      link: "https://63581c5e53902b0009dbcdc8--silly-halva-f90b4d.netlify.app/",
    },
    // {
    //   title: "Online Clothing Store - Mobile Application",
    //   subtitle: "Android Development",
    //   description:
    //     " Java,SugarORM,SQLite",
    //   image: "./project-3.gif",
    //   link: "https://reactbootcamp.com",
    // },
    // {
    //   title: "E-Learning System - Web Application",
    //   subtitle: " OOP Concepts, MVC Architecture & Web Development",
    //   description:
    //     " HTML/CSS, Java, MySQL",
    //   image: "./project-4.gif",
    //   link: "https://reactbootcamp.com",
    // },
    // {
    //   title: "Air Quality Monitoring & Prediction System - IoT & Mobile Application",
    //   subtitle: "IoT & Machine Learning",
    //   description:
    //     "ThingSpeak Cloud Service, Python , Arduino, Tensorflow, TensorFlow Lite, Google Colab, Java, Android, Firebase",
    //   image: "./project-3.png",
    //   link: "",
    // },
    // {
    //   title: "Content Management System - Desktop Application",
    //   subtitle: " Parallel Programming and Asynchronous Programming",
    //   description:
    //     " C#, .NET Framework, SQL Server ",
    //   image: "./project-4.gif",
    //   link: "",
    // },
  ];
  
  export const skills = [
    "Java",
    "C#",
    "JavaScript",
    "React",
    ".Net",
    "Spring",
    "Solidity",
    "SQL",
    "HTML",
    "Node"
  ];
  