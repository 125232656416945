import { createTheme, ThemeProvider } from '@material-ui/core'
import Intro from "./components/Intro";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

const theme = createTheme({
  palette:{
    primary: {
      main: '#33697B'
    },
    secondary: {
      main: '#FC9885'
    }
  },
  typography: {
    fontFamily: "Montserrat",
    fontWeightMedium: "300",
    h2: {
      fontFamily: "Montserrat",
      fontWeight: "200"
    },
    h4:{
      fontFamily: "Montserrat",
      fontWeight: "200"
    }
  }
})

function App() {
  return (
    
      
    <div className="App">
       <ThemeProvider theme={theme}> 
          <main>
              <Navbar/>
              <Intro/>
              <About/>
              <Projects />
              <Skills />
              <Contact />
          </main>
        </ThemeProvider> 
    </div>
        
      
    
  );
}

export default App;
