import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: "5%",
      paddingRight: "5%",
      minWidth: "100vw",
      minHeight: "100vh",
    },
    about: {
      marginTop: "12vh",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "70%",
      borderRadius: "50%",
      display: "block",
      margin: "auto",
      top: "50%",
      transform: "translateY(23%)",
    },
  };
});

export default function About() {
  const classes = useStyles();

  return (
    <section id="about">
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.about}>
            <Typography variant="h2" color="secondary">
              About Me
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Hello again! The magic of turning a bunch of confusing instructions into a cool app 
              is what got me into programming!
              <br />
              I am currently exploring new opportunities after my role at ConceptVines, Sri Lanka 
              as an Associate Software Engineer. Other than coding, I spend most of my free time
              reading, journaling & trying out DIY projects.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.image} src="../mypic.jpg" alt="profile" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
