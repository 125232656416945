import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingLeft:'5%',
            paddingRight: '5%',
            minWidth: '100vw',
            minHeight: '100vh'
        },
        intro: {
            marginTop:'20vh'
        },
        image: {
            maxWidth: '100%',
            maxHeight: '70%',
            marginTop: '27vh'
        }
    }
})

export default function Intro() {
    const classes =useStyles()

    return (
        <div>
            <Container className={classes.container}>
                <Grid container spacing ={2}>
                    <Grid item xs={12} md={6} className={classes.intro}>
                        <Typography variant="h2" color="secondary">
                        Hello! <br/> I'm Rabiya. 
                        </Typography>
                        <Typography variant="h4" color="textSecondary">
                            I love to design and develop web applications. Full-stack development is my 
                            thing – love bringing ideas to life and making them user-friendly!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img className={classes.image} src="../coding.png" alt="profile"/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
