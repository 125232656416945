import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => {
    return {
        menuBtn: {
            position: 'fixed',
            paddingTop: 17
        },
        list: {
            width: '100vw',
            height: '100vh',
            backgroundImage:'url(bg.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        listItem: {
            padding:30,
            textAlign: 'center',
            margin: 'auto',
            color: theme.palette.text.secondary,
            textDecoration: 'none',
            transition: 'transform 0.5s',
            '&:hover, &:focus': {
                transform: 'scale3d(1.5,1.5,2)',

                '&::after': {
                    opacity: 1
                }
                
            }
        }
    }
});

export default function Navbar() {
    const classes = useStyles();
    const [state,setState] = React.useState({
        left: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div 
            className={classes.list}
            role = "presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <Button><CloseIcon fontSize={'large'} color={'secondary'}/></Button>
                <ListItem button key='About'>
                    <a href='#about' className={classes.listItem}>
                    <ListItemText  primary='About'/>
                    </a>
                </ListItem>
                <ListItem button key='Projects'>
                    <a href='#projects' className={classes.listItem}>
                    <ListItemText primary='Projects' />
                    </a>
                </ListItem>
                <ListItem button key='Skills'>
                    <a href='#skills' className={classes.listItem}>
                    <ListItemText primary='Skills' />
                    </a>
                </ListItem>
                <ListItem button key='Contact'>
                    <a href='#contact' className={classes.listItem}>
                    <ListItemText primary='Contact' />
                    </a>
                </ListItem>
            
            </List>

        </div>
    );

    return (
        <div>
            <Button onClick={toggleDrawer('left', true)} className={classes.menuBtn}><MenuRoundedIcon color={'secondary'} fontSize={'large'}/></Button>
            <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
            {list('left')}
            </Drawer>
        </div>
    )
}
