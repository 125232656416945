import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Typography, Grid } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingBottom: 50,
      minWidth: "100vw",
      minHeight: "100vh",
    },
    title: {
      padding: "10vh 0",
    },
    gridItem: {
      padding: 10,
      // width: '40vw'
    },
    button: {
      transition: "transform 0.5s",
      "&:hover, &:focus": {
        transform: "scale3d(1.02,1.02,2)",
        background: "#FC9885",
        color: "#fff",

        "&::after": {
          opacity: 1,
        },
      },
    },
    details: {
      paddingLeft: 60,
      padding: 20,
    },
    footer: {
      background: "#FC9885",
      color: "#fff",
      minHeight: 40,
    },
  };
});

export default function Contact() {
  const classes = useStyles();

  function handleSubmit(e) {
    Swal.fire({
      title: "Message Sent Successfully",
      icon: "success",
      showConfirmButton: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }

  return (
    <section id="contact">
      <Container className={classes.container}>
        <Typography variant="h2" color="secondary" className={classes.title}>
          Get in Touch
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.form}>
            <form
              netlify
              name="contact"
              action="https://formsubmit.co/rabiyaf1@gmail.com"
              method="POST"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="_captcha" value="false" />
              <input
                type="hidden"
                name="_next"
                value="https://www.rabiyafaizal.com/"
              />
              <input
                type="hidden"
                name="_subject"
                value="New Email From Portfolio Website!"
              />
              <Typography variant="h6" color="textSecondary">
                If you have any questions about me or my projects, fill up the
                awesome form below and I'll get back to you as soon as possible.
              </Typography>
              <Grid Container direction="column" alignItems="center">
                <Grid item xs={12} md={12} className={classes.gridItem}>
                  <TextField
                    name="name"
                    label="Name"
                    variant="outlined"
                    className={classes.textfield}
                    fullWidth
                    sx={{ m: 1 }}
                    color="secondary"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                  <TextField
                    name="email"
                    label="Email"
                    variant="outlined"
                    className={classes.textfield}
                    fullWidth
                    sx={{ m: 1 }}
                    color="secondary"
                    type="email" 
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                  <TextField
                    name="message"
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={4}
                    className={classes.textfield}
                    fullWidth
                    sx={{ m: 1 }}
                    color="secondary"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridItem}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    size="large"
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column">
              <Grid item xs={12} md={6} className={classes.details}>
                <Typography variant="h6" color="secondary">
                  ADDRESS
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Dubai<br />
                  United Arab Emirates
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.details}>
                <Typography variant="h6" color="secondary">
                  EMAIL
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  rabiyaf1@gmail.com
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.details}>
                <Typography variant="h6" color="secondary">
                  PHONE
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  +971 55 645 2034
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        className={classes.footer}
      >
        <Typography variant="h6">
          Developed and designed with love ❤️ Ruby
        </Typography>
      </Grid>
    </section>
  );
}
