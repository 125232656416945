import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Collapse } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            maxWidth: 400,
            margin:'auto',
            transition: 'transform 0.5s',
            '&:hover, &:focus': {
                transform: 'scale3d(1.1,1.1,2)',

                '&::after': {
                    opacity: 1
                }
                
            }
        },
        media: {
            height: 200
        }
    }
})

function ProjectCard({project, checked}) {
    const classes = useStyles()

    return (
        <Collapse in={checked} {...(checked ? { timeout: 2000 } : {})}>
            <Card className={classes.card}>
                {console.log(project)}
                <CardActionArea>
                    <CardMedia
                    className={classes.media}
                    image={project.image}
                    title={project.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" color="primary">
                            <a 
                                target="_blank" href={project.link}  
                                title={project.title} 
                                style={{"text-decoration": "none"}}
                                rel="noreferrer"
                            > 
                                {project.title} 
                            </a>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {project.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Collapse>
    )
}

export default ProjectCard
