import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { projects } from "../data";
import ProjectCard from './ProjectCard';
import useWindowPosition from '../hook/useWindowPosition';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            paddingLeft:'5%',
            paddingRight: '5%',
            minWidth: '100vw',
            minHeight: '100vh'
        },
        title: {
            padding:'10vh 0'
        }
    }
})

export default function Projects() {
    const classes = useStyles();
    const checked = useWindowPosition('projects');

    return (
        <section id="projects">
            <Container className={classes.container}>
                <Typography variant="h2" color="secondary" className={classes.title}>
                Projects
                </Typography>
                    <Grid container spacing ={5}>
                        {projects.map((project) => (
                            <Grid item xs={12} md={6} key={project.id}>
                                <ProjectCard project={project} checked={checked}/>
                            </Grid>
                        ))}
                    </Grid>
            </Container>
        </section>
    )
}
